import React from 'react';
import "./styles/app.css";
import { Route } from 'react-router-dom';
import Routes from './components/TopLoadingBar';
import Home from './home';

function App() {
  return (
   <Routes>
      <Route path="/:subid" element={<Home />}></Route>
      <Route path="/" element={<Home />}></Route>
   </Routes>
  );
}

export default App;
