import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";

const Home: React.FC = () => {
  const redirectToUrl = (url: string) => {
    window.location.href = url;
  }

  useEffect(() => {
    const mobileNo = process.env.REACT_APP_MOBILENO;

    const timer = setTimeout(() => {
      redirectToUrl(`https://wa.me/${mobileNo}`);
    }, 500);
    return () => {
      clearTimeout(timer);
    }
    // eslint-disable-next-line 
  }, []);

  return (
    <div className="body" >
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>
    </div>
  );
}

export default Home;
